exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-basic-page-slug-current-tsx": () => import("./../../../src/pages/{BasicPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-basic-page-slug-current-tsx" */),
  "component---src-pages-compare-plans-tsx": () => import("./../../../src/pages/compare-plans.tsx" /* webpackChunkName: "component---src-pages-compare-plans-tsx" */),
  "component---src-pages-content-content-page-slug-current-tsx": () => import("./../../../src/pages/content/{ContentPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-content-content-page-slug-current-tsx" */),
  "component---src-pages-core-capability-slug-current-tsx": () => import("./../../../src/pages/{CoreCapability.slug__current}.tsx" /* webpackChunkName: "component---src-pages-core-capability-slug-current-tsx" */),
  "component---src-pages-customer-slug-current-tsx": () => import("./../../../src/pages/{Customer.slug__current}.tsx" /* webpackChunkName: "component---src-pages-customer-slug-current-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-feature-slug-current-tsx": () => import("./../../../src/pages/{Feature.slug__current}.tsx" /* webpackChunkName: "component---src-pages-feature-slug-current-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-integrations-page-slug-current-tsx": () => import("./../../../src/pages/integrations/{IntegrationsPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-integrations-integrations-page-slug-current-tsx" */),
  "component---src-pages-key-page-slug-current-tsx": () => import("./../../../src/pages/{KeyPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-key-page-slug-current-tsx" */),
  "component---src-pages-legal-legal-page-slug-current-tsx": () => import("./../../../src/pages/legal/{legalPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-legal-legal-page-slug-current-tsx" */),
  "component---src-pages-lp-landing-page-slug-current-tsx": () => import("./../../../src/pages/lp/{LandingPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-lp-landing-page-slug-current-tsx" */),
  "component---src-pages-page-abm-page-slug-current-tsx": () => import("./../../../src/pages/page/{AbmPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-page-abm-page-slug-current-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-product-tour-tsx": () => import("./../../../src/pages/product-tour.tsx" /* webpackChunkName: "component---src-pages-product-tour-tsx" */)
}

